import React from "react";

export class HomePage extends React.Component {
    render() {
        return (
            <div className="bg-white max-w-4xl my-5 mx-5 tablet:mx-auto rounded-xl mb-10 shadow-lg p-8">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Vagaro</h2>
                <div>Full Service Barber Shop, Salon, and Day Spa! We want to bring out the bold in you! We are a safe place for everyone and want all people who come in to feel like their best selves!</div>
                <br />
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Instagram</h2>
                <div>Siren Barber Beauty</div>
                <div className="text-gray-400">Beauty, cosmetic & personal care</div>
                <div>Let us bring out the BOLD in YOU!</div>
                <br />
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Google</h2>
                <div>"Barber Shop, Full Service Salon -Siren is a booth rent salon. That means all stylists are independent contractors. They can set their own prices, policies, and hours. Any questions or concerns you may bring to your providers attention.- Thank You!"</div>
                <br />
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Business Hours</h2>
                <div>
                    Sunday Closed <br />
                    Monday 9:00 AM - 6:00 PM <br />
                    Tuesday 9:00 AM - 6:00 PM <br />
                    Wednesday 9:00 AM - 6:00 PM <br />
                    Thursday 9:00 AM - 6:00 PM <br />
                    Friday 9:00 AM - 6:00 PM <br />
                    Saturday 10:00 AM - 2:00 PM <br />
                </div>
                <br />
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Business Info</h2>
                <div>Phone: (435) 263-1179</div>
                <div>Address: 684 South Main Street, Cedar City, UT 84720</div>
                <div>Instagram: https://www.instagram.com/siren.barber.beauty/</div>
                <div>Facebook: https://www.facebook.com/Siren.Barber.Beauty/</div>
                <div>Vagaro: https://www.vagaro.com/sirenbarberbeauty</div>
            </div >
        );
    }
}
